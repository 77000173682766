
export default {
	name: "BidCompanyList",
	data() {
		return {
			searchMechanism: "", //搜索的机构名称
			token: "",
			tableData: {},
			tableList: [],
			centerDialogVisible: false,
			addSearchMechanism: "",
			addSearchShortName: "",
			email: '',
			page: 1,
			isAddMechanism: '',
			isAdd: '',
			modifyName: false,
			mechanismName: '',
			dataId: '',
			modifyAccount: false,
			gUserEmail: '',
			gUserId: '',
			options: [{ label: '申办方', value: 2 }, { label: 'CRO', value: 1 }],
			companyType: '',
			companyTypeArr: ['CRO', '申办方'],

		};
	},

	created() {
		this.token = this.getCookie("token");
		if (this.token) {
			this.getMechanismList(this.page, "");
		}
	},

	methods: {
		getMechanismList(page, name) {
			var _this = this;
			_this.$http
				.post(
					_this.craUrl + "/selectEnterpriseList", {
					page: page,
					name: name
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (res) {
					if (res.data.code == 200) {
						_this.tableData = res.data.data;
						_this.tableList = res.data.data.records;
					} else if (res.data.code == 401) {
						_this.$router.push("/");
					}
				});
		},
		// 分页
		handleCurrentChange(val) {
			this.page = val;
			this.getMechanismList(this.page, "");
		},
		// 搜索
		getSearchMechanism(e) {
			this.getMechanismList(1, this.searchMechanism);
		},
		// 新增机构
		addMechanism(index) {
			this.isAddMechanism = index
			this.centerDialogVisible = true;
			if (this.tableList[index]) {
				this.isAdd = false
				this.isAddMechanism = this.tableList[index].id
				this.addSearchMechanism = this.tableList[index].name
				this.email = this.tableList[index].email
			} else {
				this.isAdd = true
				this.addSearchMechanism = ''
				this.addSearchShortName = ''
				this.isAddMechanism = ''
			}
			setTimeout(function () {
				var oInput = document.getElementById("xmbh");
				oInput.focus()
			}, 200)
		},
		goCraDepartment(index) {
			console.log(this.tableList[index].gUserId)
			this.$router.push({
				name: "ProjectList",
				params: {
					id: this.tableList[index].id
				}
			});
		},
		// 新增保存
		saveAddMechanism() {
			console.log(this.companyType)
			var _this = this;
			if (!_this.addSearchMechanism) {
				_this.$message({
					message: "请填写新增的申办方名称",
					type: "warning"
				});
			} else if (!_this.addSearchShortName) {
				_this.$message({
					message: "请填写新增的申办方简称",
					type: "warning"
				});
			} else {
				_this.$http
					.post(
						_this.craUrl + "/addOrUpdateBiddingParty", {
						name: _this.addSearchMechanism,
						id: _this.isAddMechanism,
						abbreviation: _this.addSearchShortName,
						email: _this.email,
						standby1: _this.companyType
					}, {
						headers: {
							token: _this.token
						}
					}
					)
					.then(function (res) {
						if (res.data.code == 200) {
							_this.centerDialogVisible = false;
							_this.getMechanismList(_this.page, "");
						} else if (res.data.code == 401) {
							_this.$router.push("/");
						}
					});
			}
		},
		// 重置密码
		resetPassword(index) {
			var _this = this;
			if (_this.tableList[index].gUserId) {
				this.$confirm("此操作将重置该申办方的登录密码, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
					.then(() => {
						_this.$http.post(_this.craUrl + '/resetPassword', {
							gUserId: _this.tableList[index].gUserId
						}, {
							headers: {
								"token": _this.token
							}
						}).then(function (res) {
							if (res.data.code == 200) {
								_this.$message({
									type: "success",
									message: "修改成功!"
								});
							}
						})

					})
					.catch(() => {

					});
			} else {
				that.$message({
					type: "warning",
					message: "不可操作!"
				});
			}


		},
		deleteMechanism(index) {
			var _this = this;
			this.$confirm("此操作将永久删除该申办方, 是否继续?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					_this.$http.post(_this.craUrl + '/delGuserEnterprise', {
						gUserId: _this.tableList[index].gUserId ? _this.tableList[index].gUserId : '',
						enterpriseId: _this.tableList[index].id
					}, {
						headers: {
							"token": _this.token
						}
					}).then(function (res) {
						if (res.data.code == 200) {
							_this.getMechanismList(_this.page, "");
							_this.$message({
								type: "success",
								message: "删除成功!"
							});
						}
					})

				})
				.catch(() => {

				});
		},
		// 修改名称
		setMechanismName(index) {
			this.modifyName = true
			this.dataId = this.tableList[index].id
			this.addSearchMechanism = this.tableList[index].name
			this.mechanismName = this.tableList[index].name
			setTimeout(function () {
				var oInput = document.getElementById("sbfmc");
				oInput.focus()
			}, 200)
		},
		saveSetMechanism() {
			var that = this
			if (this.mechanismName != this.addSearchMechanism) {
				that.$http
					.post(
						that.craUrl + "/modifyEnterprise", {
						enterpriseId: that.dataId,
						enterpriseName: that.addSearchMechanism
					}, {
						headers: {
							token: that.token
						}
					}
					)
					.then(function (res) {
						if (res.data.code == 200) {
							that.$message({
								type: "success",
								message: "修改成功!"
							});
							that.modifyName = false
							that.getMechanismList(that.page, "");
						}
					});
			} else {
				that.modifyName = false
			}
		},
		// 修改关联账号
		setEmileName(index) {
			this.modifyAccount = true
			this.dataId = this.tableList[index].id
			this.gUserId = this.tableList[index].gUserId
			this.email = this.tableList[index].gUserEmail
			this.gUserEmail = this.tableList[index].gUserEmail
			setTimeout(function () {
				var oInput = document.getElementById("glzh");
				oInput.focus()
			}, 200)
		},
		saveSetemail() {
			var that = this
			if (this.email != this.gUserEmail) {
				if (!this.email) {
					that.$message({
						type: "warning",
						message: "请填写关联账号!"
					});
				} else {
					that.$http
						.post(
							that.craUrl + "/modifyGuserEmailEnterprise", {
							enterpriseId: that.dataId,
							email: that.email,
							gUserId: that.gUserId ? that.gUserId : ''

						}, {
							headers: {
								token: that.token
							}
						}
						)
						.then(function (res) {
							if (res.data.code == 200) {
								that.$message({
									type: "success",
									message: "修改成功!"
								});
								that.modifyAccount = false
								that.getMechanismList(that.page, "");
							}
						});
				}

			} else {
				that.modifyAccount = false
			}
		},

	}
};
